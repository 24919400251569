import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function CompleteExamples() {
  return (
    <>
      <div className="section">
        <Container className="text-center">
          <Row className="justify-content-md-center">
            <Col lg="8" md="12">
              <h2 className="title">How It Works</h2>
              <h5 className="description how-it-works-steps" align="left">
                Our legal team is at your disposal, when you need them.
              </h5>
              <h5 className="description how-it-works-steps" align="left">
                <strong>Step 1:</strong> Become a WINRIGHTx Member and schedule a time to strategize with your legal team.
              </h5>
              <h5 className="description how-it-works-steps" align="left">
                <strong>Step 2:</strong> Based on your answers, we prepare a vault of information to help you and/or your business, as well as introduce you to your team of legal advisors to help you with your needs.
              </h5>
              <h5 className="description how-it-works-steps" align="left">
                <strong>Step 3:</strong> Take advantage of your monthly consultations, document review, and any AMA sessions, podcosts, webconferences we are conducting for the WINRIGHTx community.
              </h5>
              <h5 className="description how-it-works-steps" align="left">
                <strong>Step 4:</strong> Have your legal team at your disposal and have your initial legal inquiries answered - stay in constant check with your legal health.
              </h5>
              <h5 className="description how-it-works-steps" align="left">
                <strong>Step 5:</strong> If further services are required, enjoy discounted hourly rates, preferred pricing packages and priority appointment times at Winright Law.
              </h5>
              <h5 className="description how-it-works-steps" align="left">
                <strong>Step 6:</strong> Enjoy the peace of mind of having a legal team at your disposal and at your convenience, for a lifetime.
              </h5>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section section-team text-center">
        <Container>
          <div className="team">
            <Row>
              <Col md="3">
                <div className="team-player">
                  <img
                    alt="..."
                    className="rounded-circle img-fluid img-raised value-props"
                    src={require("assets/img/network.png")}
                  ></img>
                  <h4 className="title">Legal Inquiries Answered</h4>
                  <p className="category text-info value-props-description">Enjoy up to 30 minute phone consultations (up to 1 per month)</p>
                </div>
              </Col>
              <Col md="3">
                <div className="team-player">
                  <img
                    alt="..."
                    className="rounded-circle img-fluid img-raised value-props"
                    src={require("assets/img/contract.png")}
                  ></img>
                  <h4 className="title">Document Review</h4>
                  <p className="category text-info value-props-description">Have a Legal Adviser review any contracts up to 10 pages per month</p>
                </div>
              </Col>
              <Col md="3">
                <div className="team-player">
                  <img
                    alt="..."
                    className="rounded-circle img-fluid img-raised value-props"
                    src={require("assets/img/bank.png")}
                  ></img>
                  <h4 className="title">Access to Our Vault</h4>
                  <p className="category text-info value-props-description">Our Vault contains Precedents For Your Needs, Important Information and Tips for Real Estate, Business and Wills and Estates</p>
                </div>
              </Col>
              <Col md="3">
                <div className="team-player">
                  <img
                    alt="..."
                    className="rounded-circle img-fluid img-raised value-props"
                    src={require("assets/img/time-is-money.png")}
                  ></img>
                  <h4 className="title">Price Efficiency</h4>
                  <p className="category text-info value-props-description">With an affordable monthly subscription, members receive preferred pricing packages and discounted hourly rates</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <div className="team-player">
                  <img
                    alt="..."
                    className="rounded-circle img-fluid img-raised value-props"
                    src={require("assets/img/delivery-truck.png")}
                  ></img>
                  <h4 className="title">Legal at your Disposal</h4>
                  <p className="category text-info value-props-description">Our legal advisors are at your disposal given information and answers to your legal inquiries are at your disposal and at your fingertips.</p>
                </div>
              </Col>
              <Col md="3">
                <div className="team-player">
                  <img
                    alt="..."
                    className="rounded-circle img-fluid img-raised value-props"
                    src={require("assets/img/online-shop.png")}
                  ></img>
                  <h4 className="title">Legal at your Convenience</h4>
                  <p className="category text-info value-props-description">Our legal advisors are ready to meet you whenever it is convenient to you, as we’ll be endeavoring so via online platforms.</p>
                </div>
              </Col>
              <Col md="3">
                <div className="team-player">
                  <img
                    alt="..."
                    className="rounded-circle img-fluid img-raised value-props"
                    src={require("assets/img/analytics.png")}
                  ></img>
                  <h4 className="title">Peace of Mind</h4>
                  <p className="category text-info value-props-description">With your legal team intact, enjoy the peace that comes with knowing our legal team will try to assist you.</p>
                </div>
              </Col>
              <Col md="3">
                <div className="team-player">
                  <img
                    alt="..."
                    className="rounded-circle img-fluid img-raised value-props"
                    src={require("assets/img/exchange.png")}
                  ></img>
                  <h4 className="title">Immense Savings</h4>
                  <p className="category text-info value-props-description">Hourly rates for lawyers cost around $250 to $450 the second you talk to them; your initial costs will be a fraction of that price because you only pay after your initial inquiry.</p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default CompleteExamples;
