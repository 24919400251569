import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function LandingPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Who we are</h2>
                <h5 className="description">
                  Winright Law was founded from humble beginnings. Now, rapidly
                  growing in our West Broadway office, we’re shaking up the
                  legal industry. Most innovations occur as the result of a
                  belief that something can be done better. We were not
                  satisfied with the traditional practice of law, so we decided
                  to change it. Too frequently, we found that traditional law
                  firms either underappreciated clients, overworked employees,
                  or operated in the face of tremendous inefficiency.
                </h5>
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/city_hall.jpg") + ")"
                    }}
                  >
                    <p className="blockquote blockquote-info">
                      "To lead, to inspire, and to create a better legal
                      experience." <br></br>
                      <br></br>
                      <small>-Winston Kam</small>
                      <br></br>
                      <small>Lawyer, Founder, Principal</small>
                    </p>
                  </div>
                  <div
                    className="image-container"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/cards.jpg") + ")"
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/office.jpg") + ")"
                    }}
                  ></div>
                  <h3>
                    Changing Small Business Law
                  </h3>
                  <p>
                    From the beginning, our first priority has always been to do
                    right by our client. Gone is the typical hierarchical
                    structure where senior partners rank highest on the chain of
                    importance. Customer experience sits atop our pyramid.
                  </p>
                  <p>
                    At Winright we believe that happy customers are shaped by
                    happy employees, and that happy employees are shaped by a
                    great company culture. As such, it is our aspiration to build
                    a workplace environment rivalled only by the likes of
                    Zappos and Google. Though the law may be serious business,
                    not everything has to be. We are adamant that our employees
                    should feel comfortable working with us, rather than for us.
                  </p>
                  <p>
                    At Winright, we work hard to foster an open-minded, positive,
                    and friendly work environment— we even have a designated
                    Community Manager whose job it is to ensure that we stay true
                    to this. Our open concept office layout encourages
                    communication and interaction between every member of our
                    staff, regardless of position. We value what our employees
                    have to say and always accept the input of our team members
                    at face value.
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="section section-team text-center">
          <Container>
            <h2 className="title">Here is our team</h2>
            <div className="team">
              <Row>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/winston.jpg")}
                    ></img>
                    <h4 className="title">Winston Kam</h4>
                    <p className="category text-info">Lawyer, Founder, Principal</p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/davy.jpg")}
                    ></img>
                    <h4 className="title">Davy Chiu</h4>
                    <p className="category text-info">Developer</p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/arina.jpg")}
                    ></img>
                    <h4 className="title">Arina Ratsamythong</h4>
                    <p className="category text-info">Finance and Operations</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/robert.jpg")}
                    ></img>
                    <h4 className="title">Robert F. Delamar</h4>
                    <p className="category text-info">Advisor</p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/carl.jpg")}
                    ></img>
                    <h4 className="title">Carl Kwan</h4>
                    <p className="category text-info">Marketing</p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/boman.jpg")}
                    ></img>
                    <h4 className="title">Boman Chu</h4>
                    <p className="category text-info">People Strategist and Community Manager</p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/rex.jpg")}
                    ></img>
                    <h4 className="title">Rex Yeung</h4>
                    <p className="category text-info">Developer</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default LandingPage;
