import React from "react";
import { Link } from "react-router-dom";
import Mailchimp from 'react-mailchimp-form';

import 'assets/css/winright.css';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row
} from "reactstrap";

// core components

function SignUp() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  return (
    <>
      <div
        className="section section-signup"
        style={{
          backgroundImage: "url(" + require("assets/img/bg11.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          minHeight: "700px",
        }}

      >
        <Container>
          <Row>
            <Card className="card-signup" data-background-color="blue">
              <Form action="" className="form" method="">
                <CardHeader className="text-center">
                  <CardTitle className="title-up" tag="h3">
                    <p>To register, click <strong><a href="mailto:info@wrlaw.ca">here</a></strong>&nbsp;or email us <a href="mailto:info@wrlaw.ca"><strong>info@wrlaw.ca</strong></a></p>
                  </CardTitle>
                </CardHeader>

              </Form>
            </Card>
          </Row>
        </Container>
        <br></br>
        <br></br>
        <br></br>
      </div>
    </>
  );
}

export default SignUp;
