import React from "react";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function NucleoIcons() {
  return (
    <>
      <div className="section section-nucleo-icons">
        <Container>
          <Row>
            <Col lg="6" md="12">
              <h2 className="title">What is WINRIGHTx?</h2>
              <h5 className="description">
                A subscription-based membership that provides you access to a
                community of trusted legal advisors who deliver legal services
                instantaneously to you via online platforms
              </h5>
            </Col>
            <Col lg="6" md="12">
              <div className="icons-container">
                <i className="now-ui-icons ui-1_send"></i>
                <i className="now-ui-icons ui-2_like"></i>
                <i className="now-ui-icons transportation_air-baloon"></i>
                <i className="now-ui-icons text_bold"></i>
                <i className="now-ui-icons tech_headphones"></i>
                <i className="now-ui-icons emoticons_satisfied"></i>
                <i className="now-ui-icons shopping_cart-simple"></i>
                <i className="now-ui-icons objects_spaceship"></i>
                <i className="now-ui-icons media-2_note-03"></i>
                <i className="now-ui-icons ui-2_favourite-28"></i>
                <i className="now-ui-icons design_palette"></i>
                <i className="now-ui-icons clothes_tie-bow"></i>
                <i className="now-ui-icons location_pin"></i>
                <i className="now-ui-icons objects_key-25"></i>
                <i className="now-ui-icons travel_istanbul"></i>
              </div>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <Row>
            <Col lg="6" md="12">
              <h2 className="title">
                Because Finding a Legal Team to Work With Doesn’t Have to be
                Difficult.
              </h2>
              <h5 className="description">
                With enough to worry about already, the last thing you want is
                the pain of finding the right legal team to help you. Whether it
                is the difficulty of finding the time to meet your lawyer or
                finding the right price point to match what you can afford, we
                strive to offer the best of all solutions.
              </h5>
              <h5 className="description">
                Instead of paying huge lump-sum fees to have a lawyer help you
                on a one-time basis, you’re paying a small monthly membership
                fee for a legal team of advisors to stand behind you, for life.
                We’re reinventing what traditionally you would expect for your
                legal experience and making it convenient, affordable, and
                simple.
              </h5>
            </Col>
            <Col lg="6" md="12">
              <img
                alt="..."
                className="img-raised because-image"
                src={require("assets/img/group.jpg")}
              ></img>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <Row>
            <Col lg="6" md="12">
              <h2 className="title">We Offer the Delivery of Legal Services at your Fingertips. </h2>
              <h5 className="description">
                Like a traditional law firm, we are lawyers and legal advisors.
                But, Winrightx focuses on the delivery of legal services -
                online, so that you have answers to your questions at your
                disposal and at your fingertips.
              </h5>
            </Col>
            <Col lg="6" md="12">
              <img
                className="fingertips"
                src={require("assets/img/transparent2.png")}>
              </img>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default NucleoIcons;
