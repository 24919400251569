import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

// core components

function Download() {
  return (
    <>
      <div
        className="section section-download"
        data-background-color="black"
        id="download-section"
      >
        <Container>
          <Row className="justify-content-md-center">
            <Col className="text-center" lg="8" md="12">
              <h3 className="title">Membership with winrightx.com includes:</h3>
              <h5 className="description">
                Ask Me Anything Sessions or Podcasts (“AMA”)
              </h5>
              <h5 className="description">
                Webconference Seminars
              </h5>
              <h5 className="description">
                <strong>Free 1 Week Trial includes:</strong>
              </h5>
              <h5 className="description">
                30 Minute Phone Consultations (up to 1 per month)
              </h5>
              <h5 className="description">
                Document Review (for contracts up to 10 pages per month)
              </h5>
              <h5 className="description">
                Discounted Hourly Rates, Preferred Pricing Packages and Priority Appointment Times at Winright Law
              </h5>
              <h5 className="description">
                Access to Vault of Precedents For Your Needs, Important Information and Tips for Real Estate, Business and Wills and Estates
              </h5>
              <h5 className="description">
                Immense Savings (Usually, hourly rates for lawyers cost around $250 to $450)
              </h5>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <br></br>
          <Row className="justify-content-md-center sharing-area text-center">
            <Col className="text-center" lg="8" md="12">
              <h3>Thank you for supporting us!</h3>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Download;
